import React, { Component } from 'react';
import { TweenMax, Expo } from "gsap";

class NotFound extends Component {

    componentDidMount() {
        // NAVBAR
        TweenMax.staggerFrom(".item", 1.5, {
            delay: 1,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        TweenMax.staggerFrom(".work", 2.2, {
            delay: 1.5,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);
    }


    render() {

        return (
            <div>
                <div id="container_top_about">
                    <div id="navbar" className="container_top">
                        <a href="/"><h1 className="item">WOODENKODER</h1></a>
                        <a href="/About"><h2 className="item">A propos</h2></a>
                    </div>
                    <div id="work_name">
                        <p className="work">Full Stack Developpeur</p>
                        <p className="work"><i>Actuellement disponible pour des <span>projets en freelance</span></i></p>
                    </div>
                </div>
                <p className='notFound'>Y'a rien ici !<br/>Try again 🍀</p>


            </div>
         );
        }
    }
export default NotFound
