import * as PIXI from 'pixi.js'
import t1 from "../img/ball.webp";
import t2 from "../img/earth.webp";
import t3 from "../img/google-maps.webp";
import t4 from "../img/people.webp";

import fit from "math-fit";
import gsap from 'gsap';


function loadImages(paths, whenLoaded) {
    const imgs = [];
    const img0 = []
    paths.forEach(function (path) {
        const img = new Image();
        img.onload = function () {
            imgs.push(img);
            img0.push({ path, img });
            if (imgs.length === paths.length) whenLoaded(img0);
        };
        img.src = path;
    });
}

class Sketch {
    constructor() {
        this.app = new PIXI.Application({
            resizeTo: window
        });

        document.body.appendChild(this.app.view);
        this.scroll = 0;
        this.scrollTarget = 0;
        if (window.screen.availWidth <= 768) {
            this.margin = 0;
            this.width = (window.innerWidth - 2 * this.margin);
            this.height = window.innerHeight * 0.8

        }
        else if (window.screen.availWidth > 768 && window.screen.availWidth < 1300) {
            this.margin = 50;
            this.width = (window.innerWidth - 2 * this.margin) / 3;
            this.height = window.innerHeight * 0.7

        }
        else {
            this.margin = 50;
            this.width = (window.innerWidth - 2 * this.margin) / 3;
            this.height = window.innerHeight * 0.8
        }

        this.container = new PIXI.Container();
        this.app.stage.addChild(this.container);
        this.images = [t1, t2, t3, t4];
        this.WHOLEWIDTH = this.images.length * (this.width + this.margin)



        loadImages(this.images, (images) => {
            this.loadImages = images;
            this.add()
            this.render()
            this.scrollEvent();
        })


    }


    scrollEvent() {
        document.addEventListener('wheel', (e) => {
            this.scrollTarget = e.wheelDelta / 3
        }, { passive: true })
    }

    add() {
        let parent = {
            w: this.width,
            h: this.height,
        }
        this.thumbs = []
        this.loadImages.forEach((img, i) => {
            var nameEnum = {
                0: { name: "Ecocampus3", value: "/Ecocampus3", code: "ball" },
                1: { name: "Hoppin World", value: "/HoppinWorld", code: "earth" },
                2: { name: "Distrame", value: "/Distrame", code: "google-maps" },
                3: { name: "Club les Piranhas du Nord", value: "/CAPN", code: "people" }
            };


            let texture = PIXI.Texture.from(img.img)

            for (const [key, value] of Object.entries(nameEnum)) {
                if (img.path.includes(value.code)) {
                    texture.title = nameEnum[key];
                }
            }
            const sprite = new PIXI.Sprite(texture);
            let container = new PIXI.Container();
            let spriteContainer = new PIXI.Container();

            let mask = new PIXI.Sprite(PIXI.Texture.WHITE)
            mask.width = this.width;
            mask.height = this.height;

            sprite.mask = mask;


            sprite.anchor.set(0.5);
            sprite.position.set(
                sprite.texture.orig.width / 2,
                sprite.texture.orig.height / 2,
            )

            let image = {
                w: sprite.texture.orig.width,
                h: sprite.texture.orig.height,
            }

            let cover = fit(image, parent)

            spriteContainer.position.set(cover.left, cover.top)
            spriteContainer.scale.set(cover.scale, cover.scale)

            container.x = (this.margin + this.width) * i;
            container.y = this.height / 10

            spriteContainer.addChild(sprite);
            container.interactive = true;
            container.on('mouseover', this.mouseOn, { passive: true })
            container.on('mouseout', this.mouseOut, { passive: true })
            container.on('click', this.redirect, { passive: true });

            container.addChild(spriteContainer)
            container.addChild(mask);
            this.container.addChild(container);
            this.thumbs.push(container);
        })
    }

    redirect(e) {
        let e1 = e.currentTarget.children[0].children[0];
        let e2 = e1.texture.title.value;
        window.location.replace(e2);
    }

    mouseOut(e) {

        let e1 = e.currentTarget.children[0].children[0];

        gsap.to(e1.scale, {
            duration: 1,
            x: 1,
            y: 1
        })
        e1.children[0].text = "";
    }

    mouseOn(e) {
        let e1 = e.target.children[0].children[0];

        const skewStyle = new PIXI.TextStyle({
            fontFamily: 'Raleway',
            fontSize: '60px',
            dropShadowColor: '#fff',
            fill: '#fff',
            fontWeight: 'bold'

        });
        let e2 = e1.texture.title.name;
        const basicText1 = new PIXI.Text(e2, skewStyle);
        basicText1.x = -400;
        basicText1.y = 100;
        gsap.to(e1.scale, {
            duration: 1,
            x: 1.1,
            y: 1.1
        })
        if (e1.children[0]) {
            e1.children[0].text = e2;
        }
        else {
            e1.addChild(basicText1);
        }

    }

    calcPos(scr, pos) {

        let temp = (scr + pos + this.WHOLEWIDTH + this.width + this.margin) % this.WHOLEWIDTH - this.width - this.margin

        return temp;

    }

    render() {
        this.app.ticker.add(() => {
            this.app.renderer.render(this.container);
            this.scroll -= (this.scroll - this.scrollTarget) * 0.1;
            this.scrollTarget *= 0.9
            this.thumbs.forEach(th => {
                th.position.x = this.calcPos(this.scroll, th.position.x)
            })
        });
    }
}

window.mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
        // eslint-disable-next-line
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge|maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
            check = true;
    }
    )
        // eslint-disable-next-line
        (navigator.userAgent || navigator.vendor || window.opera);
    return check;
};


export default function sketch() {
    var mobileAndTabletCheck = window.mobileAndTabletCheck();

    if (!mobileAndTabletCheck) {
        new Sketch();
    }
}
