import React from 'react';

const DetailsProject = ( {detailsProjet, detailsPour, detailsDesc, detailsComme, detailsQuand, details, detailsLive, detailsKO} ) => {
    return (
        <div>
            <div id="project">
                <div id="navbar" className="container_top">
                    <h1 className="item"><a href="/">WOODENKODER</a></h1>
                    <h2 className="item"><a href="/About">A propos</a></h2>
                </div>
                <div id="container_main">

                    <div className="project_label">
                        <div className="label_1">
                            <p className="details_label">PROJET</p>
                            <p className="details">{detailsProjet}</p>
                        </div>
                        <div className="label_2">
                            <p className="details_label">DESCRIPTION</p>
                            <p className="details">{detailsDesc}</p>
                        </div>
                    </div>

                    <div className="project_label">
                        <div className="label_next">
                            <p className="details_label">COMME</p>
                            <p className="details">{detailsComme}</p>
                        </div>
                        <div className="label_next">
                            <p className="details_label">POUR</p>
                            <p className="details">{detailsPour}</p>
                        </div>
                        <div className="label_next">
                            <p className="details_label">QUAND</p>
                            <p className="details">{detailsQuand}</p>
                        </div>
                        <div className="label_next">
                            <p className="details_label">DETAILS</p>
                            <p className="details">{details}</p>
                        </div>
                    </div>
                    {(detailsLive !== "") &&
                    <div id="live">
                        <a href={detailsLive} target="_blank" rel="noopener noreferrer"><p className="details">En live <span>...</span></p></a>
                    </div>
                    }
                    {(detailsKO === "1") &&
                    <div id="live" className="horsligne">
                        <p className="details ">Non accessible.</p>
                    </div>
                    }
                    {(detailsKO === "2") &&
                    <div id="live" className="horsligne">
                        <p className="details ">Actuellement hors ligne.</p>
                    </div>
                    }
                    
                </div>
            </div>

        </div>
    );
};

export default DetailsProject;