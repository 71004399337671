import React, { Component } from 'react';
import sketch from './Sketch.js';
import { TweenMax, Expo } from "gsap";
// import { useTranslation } from "react-i18next";
// import "../translations/i18n";



class Accueil extends Component {
    componentDidMount() {
        sketch()
        // NAVBAR
        TweenMax.staggerFrom(".item", 1.5, {
            delay: 1,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        TweenMax.staggerFrom(".work", 2.2, {
            delay: 1.5,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        // WORK
        TweenMax.staggerFrom("#container-mobile", 1.5, {
            delay: 2.5,
            opacity: 0,
            x: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        // WORK
        TweenMax.staggerFrom("canvas", 1.5, {
            delay: 2.5,
            opacity: 0,
            x: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        var canvas = document.getElementsByTagName('canvas');
        var container = document.getElementById("container-mobile");
        if (canvas.length === 0) {
            container.style.display = "block";
        }
    }

    render() {

        return (
            <div>
                <div id="navbar" className="container_top">
                    <h1 className="item"><a href="/">WOODENKODER</a></h1>
                    <h2 className="item"><a href="/About">A propos</a></h2>
                </div>
                <div id="work_name">
                    <p className="work">Full Stack Developpeur</p>
                    <p className="work"><i>Actuellement disponible pour des projets en freelance</i></p>
                </div>

                <div id="container-mobile">

                    <div className="blocHorizontal">
                        <a href="/Ecocampus3">
                            <div className="slide">
                                <img src={require("../img/ball.webp")} alt="Ecocampus3"></img>
                            </div>
                        </a>
                        <a href="/HoppinWorld">
                            <div className="slide ">
                                <img src={require("../img/earth.webp")} alt="Hoppin World"></img>

                            </div>
                        </a>
                        <a href="/Distrame">
                            <div className="slide">
                                <img src={require("../img/google-maps.webp")} alt="Distrame"></img>

                            </div>
                        </a>
                        <a href="/CAPN">
                            <div className="slide">
                                <img src={require("../img/people.webp")} alt="CAPN"></img>

                            </div>
                        </a>
                    </div>

                </div>
            </div>
        );
    }
}

export default Accueil;