import React, { Component } from 'react';
import { TweenMax, Expo } from "gsap";


class About extends Component {
    componentDidMount() {
        document.body.style.overflow = "visible";
        // document.getElementById("overflow").style.overflow = "hidden";


        // NAVBAR
        TweenMax.staggerFrom(".item", 1.5, {
            delay: 1,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        TweenMax.staggerFrom(".work", 2.2, {
            delay: 1.5,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        if(window.innerWidth > 850)
        {
            TweenMax.staggerFrom("#item2", 3, {
                delay: 1.5,
                opacity: 0,
                x: "100%",
                ease: Expo.easeInOut
            }, 0.08);
        }
        else{
            TweenMax.staggerFrom("#item2", 3, {
                delay: 1.5,
                opacity: 0,
                x: "-100%",
                ease: Expo.easeInOut
            }, 0.08);
        }
  

    }

    render() {

        return (
            <div>
                <div id="container_top_about">
                    <div id="navbar" className="container_top">
                        <h1 className="item"><a href="/">WOODENKODER</a></h1>
                        <h2 className="item"><a href="/About">A propos</a></h2>
                    </div>
                    <div id="work_name">
                        <p className="work">Full Stack Developpeur</p>
                        <p className="work"><i>Actuellement disponible pour des <span>projets en freelance</span></i></p>
                    </div>
                </div>


                <div id="container_about">
                    <div id="item1" className="item_about custom" >
                    </div>
                    <div id="item2" className="item_about" align="center">
                        <div className="flex_item_about">
                            <p className="title_about">Moi, en Bref</p>
                            <p className="texte_about">
                                Je m'appelle Kévin Wernet, et j'habite actuellement à Montréal, au Canada. Je suis un développeur Full Stack avec 3 ans d'expérience qui utilise sa réactivité, sa créativité et son optimisme pour créer des sites web et gérer des projets variés.<br />
                        Je suis disponible pour une collaboration avec une entreprise afin de développer et de maintenir un site web, une API, un site vitrine, ou tout autre projet.
                        </p>

                            <p className="texte_about">
                                Tout au long de ces années, j'ai eu le plaisir de collaborer avec :
                        </p>

                            <div className="compagny_work">
                                <ul>
                                    <li><a href="https://www.distrame.fr/fr/" target="_blank" rel="noopener noreferrer">Distrame</a></li>
                                    <li><a href="https://www.facebook.com/ecocampus3/" target="_blank" rel="noopener noreferrer">Ecocampus3</a></li>
                                    <li><a href="https://www.ecocea.com/" target="_blank" rel="noopener noreferrer">Ecocea</a></li>
                                    <li><a href="https://ca.louisvuitton.com/fra-ca/homepage" target="_blank" rel="noopener noreferrer">Louis Vuitton</a></li>
                                    <li><a href="https://www.manutan.fr/fr/maf" target="_blank" rel="noopener noreferrer">Manutan</a></li>
                                    <li><a href="https://www.alithya.com/fr/" target="_blank" rel="noopener noreferrer">Alithya</a></li>
                                    <li><a href="https://www.desjardins.com/" target="_blank" rel="noopener noreferrer">Desjardins</a></li>
                                    <li><a href="https://hoppin.world/" target="_blank" rel="noopener noreferrer">Hoppin World</a></li>
                                    <li><a href="https://www.clubcapn.com/" target="_blank" rel="noopener noreferrer">CAPN</a></li>
                                </ul>
                            </div>

                            <p className="title_about">Compétences Techniques</p>
                            <div className="skill_about">
                                <ul className="flex_ul">
                                    <li><span>Langages</span></li>
                                    <ul className="flex_li">
                                        <li>HTML5</li>
                                        <li>CSS3</li>
                                        <li>Java</li>
                                        <li>Javascript</li>
                                        <li>PHP</li>
                                        <li>SQL</li>
                                    </ul>
                                </ul>
                                <ul className="flex_ul">
                                    <li><span>Frameworks</span></li>
                                    <ul className="flex_li">
                                        <li>React</li>
                                        <li>Spring Boot</li>
                                        <li>Express JS</li>
                                        <li>PIXI.js</li>
                                        <li>GSAP</li>
                                        <li>IBM Websphere Commerce</li>
                                    </ul>
                                </ul>
                                <ul className="flex_ul">
                                    <li><span>DevOps</span></li>
                                    <ul className="flex_li">
                                        <li>Git</li>
                                        <li>Bitbucket</li>
                                        <li>PCF</li>
                                        <li>Azure</li>
                                        <li>Concourse</li>
                                        <li>Splunk</li>
                                        <li>Firebase</li>
                                    </ul>
                                </ul>
                                <ul className="flex_ul">
                                    <li><span>QA</span></li>
                                    <ul className="flex_li">
                                        <li>JUnit</li>
                                        <li>Cucumber</li>
                                        <li>Gatling</li>
                                    </ul>
                                </ul>
                            </div>
                            <p className="title_about">Contactez moi sur</p>
                            <div className="contact_list">
                                <ul>
                                    <li><a href="https://www.linkedin.com/in/kevin-wernet/" target="_blank" rel="noopener noreferrer">Linkedin</a></li>
                                    <li><a href="https://github.com/wernetke" target="_blank" rel="noopener noreferrer">Github</a></li>
                                    <li><a href="mailto: kvn.wernet@gmail.com" target="_blank" rel="noopener noreferrer">Email</a></li>
                                    <li>Notion</li>
                                </ul>
                            </div>

                            <p className="texte_about last">
                                N'hésitez pas à me contacter afin de faire connaissance et de parler de vos futurs projets autour d'un bon café <span role="img" aria-label="coffee">☕</span>
                            </p>

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default About;