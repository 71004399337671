import React, { Component } from 'react';
import DetailsProject from './DetailsProject.js'
import gsap, { TweenMax, Expo } from "gsap";
import LocomotiveScroll from "locomotive-scroll";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

class Distrame extends Component {

    componentDidMount() {

        // NAVBAR
        TweenMax.staggerFrom(".item", 1.5, {
            delay: 1,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        TweenMax.staggerFrom(".project_label", 2.2, {
            delay: 1.5,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        TweenMax.staggerFrom("#live", 2.2, {
            delay: 1.8,
            opacity: 0,
            y: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        TweenMax.staggerFrom("#container_second", 2.2, {
            delay: 2,
            opacity: 0,
            x: "-100%",
            ease: Expo.easeInOut
        }, 0.08);

        // document.body.style.overflow = "visible";
        gsap.registerPlugin(ScrollTrigger);

        // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll

        const locoScroll = new LocomotiveScroll({
            el: document.querySelector(".smooth"),
            smooth: true,
            smartphone: {
                smooth: true
            },
            tablet: {
                smooth: true
            },
        });

        locoScroll.on("scroll", ScrollTrigger.update);

        // tell ScrollTrigger to use these proxy methods for the ".data-scroll-container" element since Locomotive Scroll is hijacking things
        ScrollTrigger.scrollerProxy(".smooth", {
            scrollTop(value) {
                return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
                return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
            }
        });

        // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
        ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

        // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
        ScrollTrigger.refresh();
    }

    render() {
        return (
            <div>
                <div className="wrapper smooth">
                    <div data-scroll-section>
                        <DetailsProject
                            detailsProjet={"Module De Suivi"}
                            detailsDesc={"Mise en place d'une cellule de suivi permettant de géolocaliser les modules et son intégration sur la plateforme de l'agence avec des services liés aux alertes des différents capteurs."}
                            detailsPour={"Distrame"}
                            detailsComme={"Développeur Full Stack"}
                            detailsQuand={"2017"}
                            details={"PHP, MySQL, JavaScript, Google Maps API, HTML, CSS"}
                            detailsLive={""}
                            detailsKO={"1"}
                        />
                    </div>
                    <div id="container_second" data-scroll-container>
                        <div data-scroll-section>

                            <div className="block_image_flex bloc_2 bloc_4">
                                <div className="container_1_bloc" data-scroll data-scroll-speed="1">
                                    <img className="container_1" src={require("../img/distrame11.webp")} alt="Distrame capteur details" />
                                </div>
                                <div className="item_bloc_2 " data-scroll data-scroll-speed="3" >
                                    <img src={require("../img/distrame5.webp")} alt="Distrame type zone" />
                                </div>
                                <div className="item_bloc_2 opac" data-scroll data-scroll-speed="-1" >
                                    <img src={require("../img/distrame6.webp")} alt="Distrame alerte" />
                                </div>
                                <div className="item_bloc_2" data-scroll data-scroll-speed="3" >
                                    <img src={require("../img/distrame7.webp")} alt="Distrame batterie" />
                                </div>

                                <div className="item_bloc_2 item_flex_2" data-scroll data-scroll-speed="4" >
                                    <img src={require("../img/distrame8.webp")} alt="Distrame map affichage" />
                                </div>
                                <div className="item_bloc_2 item_flex_2 opac" data-scroll data-scroll-speed="-2" >
                                    <img src={require("../img/distrame9.webp")} alt="Distrame zone affichage" />
                                </div>


                            </div>

                        </div>

                        <div data-scroll-section>
                            <div className="container_2_bloc bloc_3" data-scroll data-scroll-speed="1" >
                                <img className="container_2" src={require("../img/distrame4.webp")} alt="Distrame affichage" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default Distrame;