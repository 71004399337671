import React from 'react'
import ReactDOM from 'react-dom'
import Accueil from './components/Accueil'
import Distrame from './components/Distrame'
import Ecocampus3 from './components/Ecocampus3'
import Hoppin from './components/Hoppin'
import CAPN from './components/CAPN'
import About from './components/About'
import NotFound from './components/NotFound'


import './style.css'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

const Root = () => (
  <Router>
    <Switch>
      <Route exact path='/' component={Accueil} />
      <Route path='/Distrame' component={Distrame} />
      <Route path='/Ecocampus3' component={Ecocampus3} />
      <Route path='/HoppinWorld' component={Hoppin} />
      <Route path='/CAPN' component={CAPN} />
      <Route path='/About' component={About} />
      <Route component={NotFound} />


    </Switch>
  </Router>
)

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
